<template>
  <menu class="navigation-top">
    <nav class="navigation-top__container">
      <section class="navigation-top__list">
        <router-link :to="{ name: 'home' }" class="navigation-top__logo">
          <img class="navigation-top__img" src="/images/Logo_CAG.png" alt="Logo Conception Alain Gagné" />
        </router-link>

        <ul class="navigation-top__list -mobile">
          <router-link :to="{ name: 'home', hash: '#home' }" class="navigation-top__item">À propos</router-link>
          <router-link :to="{ name: 'home', hash: '#about' }" class="navigation-top__item">Services</router-link>
          <router-link :to="{ name: 'home', hash: '#expertise' }" class="navigation-top__item">Expertise</router-link>
          <router-link :to="{ name: 'home', hash: '#clients' }" class="navigation-top__item">Clients</router-link>
        </ul>
      </section>

      <section class="navigation-top__list">
        <ul class="navigation-top__list">
          <router-link :to="{ name: 'home', hash: '#contact' }" class="navigation-top__item -bold">
            Nous contacter
          </router-link>

          <router-link :to="{ name: 'portfolio' }" class="button -small -light">
            Voir notre portfolio
          </router-link>
        </ul>

        <div class="navigation-top__burger" @click="$emit('toggle-sidenav')">
          <svg-icon id="menu" />
        </div>
      </section>
    </nav>
  </menu>
</template>

<script>
export default {
  name: 'NavigationTop'
}
</script>
