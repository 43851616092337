<template>
  <article id="contact" class="layout-section -bg-darker -row">
    <a href="https://goo.gl/maps/95NhqdnRUjbM78E89" target="_blank" class="layout-section__figure" style="background-image: url(/images/map.png);" />

    <section class="layout-section__split">
      <h2 class="layout-section__title">Vous avez un projet de décors ?</h2>
      <p>Pour toutes informations concernant l’élaboration de votre projet ou pour toute demande de soumission, il nous fera plaisir de communiquer avec dans les plus brefs délais.</p>

      <ul class="content-list -spacing">
        <li class="content-list__item">
          <span />
          <a href="tel:418-521-5752">418-521-5752</a>
        </li>

        <li class="content-list__item">
          <span />
          <a href="mailto:alaingagne@decors.ca">alaingagne@decors.ca</a>
        </li>
      </ul>
    </section>
  </article>
</template>

<script>
export default {
  name: 'HomeContact'
}
</script>
