export default [
  {
    name: 'Théâtre & Opéra',
    description:
      'Le décor de scène est bien plus qu’un simple arrière-plan : il est une extension de l’histoire, un élément essentiel qui transporte le spectateur dans un univers unique. Chaque décor est conçu pour servir la mise en scène, renforcer l’émotion et donner vie à l’imaginaire des œuvres.',
    thumbnail: 'theatre.png',
    url: 'theatre-opera',
    credit:
      'Accréditation des photos: Catherine Charron-Drolet, Emmanuel Burriel, Jessica Latouche, Vincent Champoux, Silverchip Photos',
    images: [
      'opera2.png',
      'opera3.png',
      't+o2.png',
      't+o3.png',
      'opera4.png',
      'theatre1.png',
      'opera1.png',
      'opera5.png',
      'opera6.png',
      'opera7.png',
      'opera8.png',
      'theatre2.png',
      'opera9.png',
      'theatre3.jpg',
      'theatre4.jpg',
      'theatre5.jpg',
      'theatre6.jpg',
      't+o1.png',
      't+o4.png',
      't+o5.png',
      't+o6.png',
      't+o7.png',
      't+o10.jpeg',
      't+o8.png',
      't+o9.png',
      'to10.png',
      'to21.jpg',
      'to11.jpeg',
      'to12.jpg',
      'to13.jpg',
      'to14.jpg',
      'to15.jpg',
      'to16.jpg',
      'to22.jpg',
      'to17.jpg',
      'to18.jpg',
      'to19.jpg',
      'to23.png',
      'to20.jpg'
    ]
  },
  {
    name: 'Scupltures',
    description:
      'Quel que soit le style, la sculpture repousse les limites de la création, transformant l’espace et éveille l’imagination! Nous réalisons des projets de toutes les tailles avec plusieurs types de matériaux.',
    thumbnail: 'sculpture.png',
    url: 'sculptures',
    images: [
      'bottes.jpg',
      'sculpture1.png',
      'sculpture2.png',
      'sculpture3.png',
      'sculpture4.png',
      'sculpture5.png',
      'sculpture-produits.png',
      'sculpture7.png',
      'sculpture10.jpg',
      'ccbn-chapeau.jpeg',
      'arbre2.png',
      'sculpture8.png',
      'ananas.png',
      'guitare-2.jpg',
      'sculpture12.jpg',
      'sculpture13.jpg',
      'sculpture9.jpg',
      'sculpture14.png',
      'sculpture15.jpg',
      'sculpture16.jpg',
      'sculpture6.jpg',
      'sculpture17.png',
      'sculpture18.png',
      'abeille.png',
      'sculpture19.jpg',
      'arbre3.jpeg',
      'pops.jpeg',
      'ccbn-2.png',
      'guitare.png'
    ]
  },
  {
    name: 'Kiosque produits',
    description:
      'Qu’ils soient installés dans un centre commercial, un marché ou un événement, les kiosques sont des espaces stratégiques qui attirent l’attention et mettent en valeur les produits de nos clients.',
    thumbnail: 'produit.png',
    url: 'kiosque-produits',
    images: [
      'kiosque13.png',
      'kiosque6.png',
      'kiosque3.png',
      'produits1.png',
      'kiosque1.png',
      'kiosque7.png',
      'produits3.png',
      'kiosque14.png',
      'produits2.png',
      'kiosque4.png',
      'kiosque2.png',
      'kiosque10.png',

      'kiosque12.jpg',
      'kiosque15.png',
      'kiosque5.png',

      'kiosque11.jpg',
      'kiosque8.png',

      'kiosque17.jpg',
      'kiosque16.jpg',
      'kiosque9.png',
      'kiosque18.jpg'
    ]
  },
  {
    name: 'Affichage',
    description:
      'Nous réalisons et installons des affiches de toutes tailles, adaptées à tous types d’événements : salons, festivals, promotions commerciales, et bien plus encore. De la conception en atelier à la pose sur site, nous vous offrons un service clé en main pour garantir un impact visuel optimal.',
    thumbnail: 'affichage.png',
    url: 'affichage',
    images: [
      'affichage1.jpeg',
      'affichage10.jpeg',
      'affichage4.jpeg',
      'affichage11.jpeg',
      'affichage5.jpeg',
      'affichage8.jpeg',
      'affichage3.jpeg',
      'affichage9.jpeg',
      'affichage13.jpeg',
      'affichage12.png',
      'affichage2.jpeg',
      'affichage14.jpeg',
      'affichage15.jpeg',
      'affichage16.jpeg',
      'affichage6.jpeg',
      'affichage20.png',
      'affichage18.jpeg',
      'affichage17.jpeg',
      'affichage23.png',
      'affichage22.png',
      'affichage21.jpeg',
      'affichage24.jpeg'
    ]
  },
  {
    name: 'Expositions',
    description:
      'Réaliser un décor pour une exposition, c’est concevoir un espace immersif qui met en valeur les œuvres ou les produits tout en guidant le regard et l’expérience du visiteur. Chaque élément du décor joue un rôle essentiel : il raconte une histoire, crée une ambiance et renforce l’impact du message à transmettre.',
    thumbnail: 'exposition.png',
    url: 'expositions',
    images: [
      'expo2.jpeg',
      'cercle-polaire2.jpeg',
      'ccbn1.png',
      'expo8.png',
      'expo13.jpg',
      'expo3.jpeg',
      'expo-mode.png',
      'cercle-polaire1.png',
      'expo9.png',
      'ccbn2.jpeg',
      'expo5.jpg',
      'expo4.jpeg',
      'expo11.jpg',
      'LGBTQ.png',
      'expo17.png',
      'expo12.png',
      'broue.png',
      'machine.jpeg',
      'expo14.jpeg',
      'expo6.jpg',
      'expo10.jpg',
      'miracles.jpeg',
      'ccbn2.png',
      'expo15.jpeg',
      'expo16.png'
    ]
  },
  {
    name: 'Soirées privées',
    description:
      'Le décor d’une soirée privée est bien plus qu’un simple habillage : il crée une atmosphère, transporte les invités dans un univers et donne le ton de l’événement. Nous réalisons des decors pour des soirées corporatifs, des mariages, des événements speciaux, etc.',
    thumbnail: 'soiree.png',
    url: 'soirees-privees',
    images: [
      'soiree1.jpeg',
      'soiree2.jpeg',
      'soiree3.png',
      'soiree8.jpeg',
      'soiree6.png',
      'soiree5.jpeg',
      'soiree4.jpeg',
      'soiree9.jpg',
      'soiree10.jpg',
      'soiree11.jpeg',
      'soiree7.jpg',
      'soiree13.jpg',
      'soiree12.png',
      'soiree14.png',
      'soiree15.png'
    ]
  },
  {
    name: 'Photomatons',
    description:
      'Les photomatons sont devenus très populaires ces dernières années, que ce soit pour les créateurs de contenu ou pour les familles! Nous avons réalisé plusieurs espaces qui ont été installés dans les centres commerciaux (Laurier Québec, les Galeries d’Anjou), mais aussi à l’extérieur (Festival d’été de Québec).',
    thumbnail: 'photo-booth.png',
    url: 'photomatons',
    images: [
      'PhotoBooth1.png',
      'PhotoBooth2.png',
      'PhotoBooth6.jpeg',
      'PhotoBooth3.png',
      'PhotoBooth7.jpeg',
      'PhotoBooth5.png',
      'PhotoBooth8.png',
      'PhotoBooth4.jpeg',
      'feq.png',
      'laurier.png'
    ]
  },
  {
    name: 'Commercial',
    description: '',
    thumbnail: 'commercial.png',
    url: 'commercial',
    images: [
      'commercial4.jpeg',
      'commercial6.jpg',
      'commercial5.jpeg',
      'commercial2.jpg',
      'commercial3.jpeg',
      'commercial8.jpeg',
      'commercial1.jpg',
      'commercial7.jpg'
    ]
  },
  {
    name: 'Lettres geantes',
    description:
      'Réalisation de lettres/logo en grand format! Qu’il s’agisse d’un salon professionnel, d’un lancement de produit ou d’un aménagement urbain, chaque logo géant doit être pensé pour impressionner tout en restant fidèle à l’image de marque.',
    thumbnail: 'lettres.png',
    url: 'lettres-geantes',
    images: [
      'kwe.png',
      'blizz.png',
      'mbdq.jpeg',
      'bmr2.jpeg',
      'feq1.png',
      'e.png',
      'feq2.jpg',
      'pls.jpeg',
      'bmr1.jpeg',
      'joe.jpeg'
    ]
  },
  {
    name: 'Végétation',
    description:
      'Les aménagements végétaux artificiels sont une alternative efficace pour intégrer de la verdure dans tous types d’espaces, sans les contraintes liées à l’entretien des plantes naturelles. Nous réalisons des murs végétaux, des jardins, des plantes, des arbres, etc.',
    thumbnail: 'vegetation.png',
    url: 'vegetation',
    images: [
      'vegetation1.png',
      'arbre1.png',
      'vegetation9.jpg',
      'vegetation3.png',
      'vegetation4.png',
      'vegetation11.png',
      'vegetation5.png',
      'vegetation10.png',
      'vegetation6.png',
      'vegetation7.png',
      'vegetation8.png',
      'vegetation2.png',
      'arbre3.png'
    ]
  },
  {
    name: 'Modelage',
    description:
      'Le modelage est une technique de sculpture qui se pratique sur des matières malléables (comme le PVC). Au fils des années, nous avons développé une expertise dans la création de fleurs. Nous pouvons les créer de toutes les tailles, formes et couleurs! De plus, elles peuvent être exposés à l’intérieur comme à l’extérieur.',
    thumbnail: 'modelage.png',
    url: 'modelage',
    images: ['fleur1.png', 'modelage-bfly2.png', 'fleur3.png', 'fleur5.png', 'fleur4.png', 'modelage-bfly1.png']
  },
  {
    name: 'Faux finis',
    description:
      "La création de faux finis en peinture est une technique permettant d'imiter à la perfection divers matériaux comme le bois, la pierre, la brique, la glace ou le métal. Nos techniques avancées de peinture et de patine nous permettent de reproduire fidèlement l'apparence et la texture des matériaux naturels, avec un souci du détail qui garantit un résultat bluffant.",
    thumbnail: 'pierres.png',
    url: 'faux-finis',
    images: [
      'faux-fini2.png',
      'pierre2.png',
      'pierre6.png',
      'faux-fini1.png',
      'pierre5.png',
      'pierre4.png',
      'volcan.png',
      'pierre3.png',
      'bfly-grotte.jpg',
      'pierre9.png',
      'bfly-glace.jpg',
      'pierre8.png',
      'pierre1.png'
    ]
  },
  {
    name: 'Murales',
    description:
      'Les murales transforment les espaces publics en véritables expériences visuelles. Elles peuvent également jouer un rôle crucial dans le développement des enfants en réadaptation!',
    thumbnail: 'murale.png',
    url: 'murales',
    images: [
      'murale-9.jpg',
      'murale-3.jpg',
      'murale-10.jpg',
      'murale-2.jpg',
      'murale-4.jpg',
      'murale-1.jpg',
      'murale-15.png',
      'murale-5.jpg',
      'murale-6.jpg',
      'murale-7.jpg',
      'murale-13.png',
      'murale-8.jpg',
      'murale-12.png',
      'murale-11.jpg',
      'murale-14.png',
      'murale-16.png',
      'murale-17.png',
      'murale-18.jpg',
      'murale-19.png'
    ]
  },
  {
    name: 'Jeux',
    description: '',
    thumbnail: 'jeux.png',
    url: 'jeux',
    images: [
      'jeux1.png',
      'jeux2.png',
      'jeux3.jpg',
      'jeux4.png',
      'jeux5.jpg',
      'jeux12.jpg',
      'jeux8.jpg',
      'jeux6.jpg',
      'jeux13.jpg',
      'jeux9.png',
      'jeux16.jpeg',
      'jeux14.jpg',
      'jeux7.jpg',
      'jeux10.jpg',
      'jeux15.png',
      'jeux18.jpeg',
      'jeux17.jpeg',
      'jeux11.jpg',
      'jeux19.jpeg'
    ]
  },
  {
    name: 'Terrasses',
    description:
      'Nous réalisons des terrasses et du mobilier extérieur sur mesure, alliant esthétique, durabilité et confort. Profitez pleinement de vos espaces extérieurs avec des créations uniques et adaptées à vos besoins.',
    thumbnail: 'terrasses.png',
    url: 'terrasses',
    images: [
      'terrasse.jpeg',
      'terrasse3.jpeg',
      'terrasse1.jpeg',
      'terrasse10.jpeg',
      'terrasse5.jpg',
      'terrasse13.png',
      'terrasse7.jpg',
      'terrasse6.jpeg',
      'terrasse8.jpeg',
      'terrasse9.png',
      'terrasse11.jpeg',
      'terrasse12.jpeg',
      'terrasse14.png',
      'terrasse4.jpeg'
    ]
  },
  {
    name: 'Fêtes',
    description:
      'Créer un décor festif, c’est offrir un univers enchanteur qui émerveille petits et grands. Grâce à des éléments bien choisis et une mise en scène équilibrée, l’esprit des fêtes prend vie dans chaque espace, rendant cette période encore plus magique et mémorable.',
    thumbnail: 'fetes.png',
    url: 'fetes',
    images: [
      'fetes1.png',
      'fetes2.png',
      'fetes3.png',
      'fetes4.jpeg',
      'fetes7.jpeg',
      'fetes9.jpeg',
      'fetes12.jpeg',
      'fetes8.jpeg',
      'fetes5.png',
      'fetes6.png',
      'fetes10.jpeg',
      'fetes11.jpeg'
    ]
  }
]
