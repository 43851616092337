<template>
  <header class="page-header -spacing">
    <h1 class="page-header__title">Notre portfolio</h1>
    <p>Découvrez les différents décors que nous avons fabriqué au courant des dernières années. Pour toutes demandes d'informations  <router-link :to="{ name: 'home', hash: '#contact' }" class="link -accent">contactez nous</router-link> et il nous fera plaisir de communiquer avec dans les plus brefs délais.</p>
  </header>
</template>

<script>
export default {
  name: 'PortfolioHeader'
}
</script>
