<template>
  <router-link :to="{ name: 'project', params: { projectId: url } }" class="layout-grid__item link">
    <figure class="layout-grid__figure">
      <div class="layout-grid__image" :style="{ backgroundImage }"/>
    </figure>

    <div class="layout-grid__hgroup">
      <h2 class="layout-grid__title">{{ name }}</h2>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'PortfolioSingle',
  props: {
    url: { type: String, required: true },
    name: { type: String, required: true },
    thumbnail: { type: String, required: true },
  },
  computed: {
    backgroundImage() {
      return `url('/images/portfolio/thumbnails/${this.thumbnail}')`
    }
  }
}
</script>
