<template>
  <section class="body__container -bg-light">
    <PortfolioHeader />
    <PortfolioIndex />
  </section>
</template>

<script>
import * as partials from './partials'

export default {
  name: 'Portfolio',
  components: { ...partials },
  isPage: true
}
</script>
