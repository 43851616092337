<template>
  <article id="about" class="layout-section -row-reverse">
    <figure class="layout-section__figure" style="background-image: url('/images/about-1.jpg');"/>

    <section class="layout-section__split -reverse">
      <h2 class="layout-section__title">Plus de 20 ans d'experience dans la <span>réalisation de décors</span></h2>
      <p>Une équipe de professionnel s’active en atelier afin de réaliser un décor adapté à votre représentation. Notre atelier est équipé pour réaliser des projets en tout genres.</p>

      <div class="layout-section__cta">
        <router-link to="/portfolio" class="button">Voir notre portfolio</router-link>
        <a href="#contact" class="button -dark">Nous contacter</a>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: 'HomeAbout'
}
</script>
