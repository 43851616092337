<template>
  <section class="body__container -bg-light">
    <header class="page-header -light">
      <router-link to="/portfolio" class="page-header__back">
        <svg-icon id="arrow-full" />
        <span>Retour</span>
      </router-link>

      <h1 class="page-header__title">{{ selectedProject.name }}</h1>
      <p>{{ selectedProject.description }}</p>
    </header>

    <section class="layout-grid">
      <article v-for="image in selectedProject.images" :key="image" class="layout-grid__item">
        <figure class="layout-grid__figure" :style="{ backgroundImage: `url('/images/portfolio/${projectId}/${image}')` }" />
      </article>
    </section>

    <div class="layout-grid__footnote">
      <p class="">{{ selectedProject.credit }}</p>
    </div>
  </section>
</template>

<script>
import portfolioEntries from '@/assets/data'

export default {
  name: 'Project',
  isPage: true,
  props: {
    projectId: { type: String, default: null }
  },
  computed: {
    selectedProject() {
      return portfolioEntries.find(entry => entry.url === this.projectId) || {}
    }
  }
}
</script>
