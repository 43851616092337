<template>
  <section class="layout-grid">
    <PortfolioSingle v-for="entry in portfolioEntries" :key="entry.url" v-bind="entry" />
  </section>
</template>

<script>
import PortfolioSingle from './PortfolioSingle.vue'
import portfolioEntries from '@/assets/data'

export default {
  name: 'PortfolioIndex',
  components: { PortfolioSingle },
  props: {
    projectId: { type: String, default: null }
  },
  data() {
    return { portfolioEntries }
  },
  computed: {
    selectedProject() {
      return this.portfolioEntries[this.projectId]
    }
  }
}
</script>
