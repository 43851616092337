<template>
  <article class="layout__overlay">
    <section class="overlay">
      <h1 class="overlay__title">Photo — Nom du projet</h1>

      <figure class="overlay__figure"/>

      <div>
        <h6>Informations</h6>

        <ul class="content-list">
          <li>Création : Aout 2020</li>
          <li>Scénographe : </li>
        </ul>
      </div>

        <h6>Tags associés</h6>
        <ul class="layout-filters__container">
          <li class="tag">Commercial</li>
          <li class="tag">Menuiserie</li>
          <li class="tag">Soudure</li>
          <li class="tag">Faux-finis</li>
        </ul>

    </section>
  </article>
</template>

<script>
export default {
  name: 'OverlayBox',
  props: {
    name: { type: String, required: true },
    type: { type: String, required: true },
    client: { type: String, required: true },
    image: { type: String, required: true },
    tags: { type: Array, default: Array },
    date: { type: String, required: true },
    participants: { type: Array, default: Array }
  }
}
</script>
